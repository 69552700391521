import React from "react"
import FullwidthBox from "../../components/fullwidthBox/fullwidthBox"
import Layout from "../../components/layout/layout"
import styled from 'styled-components'
import Title from "../../components/title/title"
import TxtImg from "../../components/txtImg/txtImg"
import { graphql } from "gatsby"
import LondonModular from "../../images/London-Modular.jpg"
import { Helmet } from "react-helmet"

const BgWhite = styled.section`
  background-color: var(--neutral-100);
  padding-bottom: 5rem;
`;

export default function Storage({ data }) {
  return (
    <Layout>
      <Helmet>
        <meta charSet="UTF-8" />
        <title>Solar Energy Storage | Caplin Solar</title>
        <html lang="en"></html>
      </Helmet>
      <Title
        title="Thermal Energy Storage"
        subtitle="Caplin Solar’s patented Earth Energy Bank is an inter-seasonal thermal store that preserves the heat collected in the summer for use during the winter months."
        gradient={true}
      />
      <BgWhite>
        <TxtImg heading="Earth Energy Bank" headingColor="accent" imgSrc={data.eebImage.childImageSharp.fluid} imgAlt="Earth Energy Bank illustration">
          <p>Our thermal energy storage technology, the Earth Energy Bank, takes advantage of the high thermal capacity and low conductivity of the earth to store heat underground. It features a matrix of shallow boreholes 1.5 meters deep and 1.5 meters apart, which sit within insulated foundations beneath the footprint of a new building. Heated fluid pumped from an array of PV-T panels on the roof runs through these bores to thermally “charge” the ground under the house during warm periods. This heat can then be drawn upon by a heat pump as required during the colder days.</p>
        </TxtImg>
        <div className="stdWidth" style={{marginTop: "-3.5rem"}}>
          <p> The polyethylene pipes that carry this heated fluid are electro-welded into sub-assemblies off-site and delivered to site ready to be dropped into the boreholes. The PE100 pipe, commonly used in the geothermal industry, is extremely robust and chemically inert. The Earth Energy Bank installation is then pressurised and tested to 5 times the operating system pressure.</p>
          <p>The system has obvious advantages over conventional geothermal methods such as ground loops, which require a large area of land to accommodate, or deep-hole bores, which can be prohibitively expensive due to the cost of deep-drilling equipment hire and transport. Additionally both of these technologies feature a uni-directional flow of heat from the ground, as a result the surrounding earth mass is always below ambient. I contrast, as the heat drawn from Earth Energy Bank’s is replaced or “recharged” the temperature of the earth mass varies either side of ambient +/-7 degrees over a full year. This enables the heat pump to operate at a much higher level of efficiency.</p>
          <p>The EEB is cheaper and uses less space than traditional systems, making achieving low and zero carbon much more affordable.</p>
        </div>
      </BgWhite>
      <FullwidthBox image={LondonModular}>
        The <span>Earth Energy Bank</span> is simple, quick to install and inexpensive. It requires no additional land to be excavated, no expensive deep boring equipment to be used. It is contained entirely within the building footprint, making it ideal for use in multi-occupancy developments and housing estates.
      </FullwidthBox> 
    </Layout>
  )
}

export const query = graphql`
  query {
    eebImage: file(relativePath: {eq: "images/EEB.jpg"}) {
      childImageSharp {
        fluid(maxWidth: 790, quality: 72) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`